/* Miscellanoeous */
body,
html {
  overflow-x: hidden;
  padding-right: 0; // resets padding right added by Bootstrap modal
}

*:-moz-full-screen,
*:-webkit-full-screen,
*:fullscreen *:-ms-fullscreen {
  overflow: auto;
}

.page-body-wrapper {
  background: $content-bg;
  padding-left: 0;
  padding-right: 0;

  &.full-page-wrapper {
    width: 100%;
    min-height: 100vh;
  }
}

.main-panel {
  padding-top: 130px;
  @include media-breakpoint-down(md) {
    padding-top: 70px;
  }
  @include make-container();
  @include make-container-max-widths();
}

.content-wrapper {
  padding: 1.75rem 0;
  width: 100%;
  min-height: calc(100vh - #{$horizontal-navbar-height} - #{$footer-height});
  @include flex-grow(1);
}

.container-scroller {
  overflow: hidden;
}

.scroll-container {
  position: relative;

  &.horizontally {
    overflow-x: hidden;
    width: 100%;
    max-width: 100%;
  }

  &.vertically {
    overflow-y: hidden;
    height: 100%;
    max-height: 100%;
  }
}

pre {
  background: color(gray-lighter);
  padding: 15px;
  font-size: 14px;
}

.fade {
  &.show,&.in {
    opacity: 1;
  }
}

.page-header {
  margin: 0 0 2rem 0;
  .breadcrumb {
    border: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    .breadcrumb-item {
      line-height: 1;
    }
  }
}
.page-title {
  color: $black;
  font-size: 1.25rem;
  margin-bottom: 0;
  .page-title-icon {
    display: inline-block;
    width: 36px;
    height: 36px;
    border-radius: 4px;
    text-align: center;
    box-shadow: 0px 3px 8.3px 0.7px rgba(163, 93, 255, 0.35);
    i {
      font-size: .9375rem;
      line-height: 36px;
    }
  }
}